var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',{scopedSlots:_vm._u([(_vm.getCurrentRole !== 31)?{key:"block",fn:function(){return [_vm._v(" "+_vm._s(_vm.sourceObject.results ? _vm.sourceObject.results.is_active === false ? "Заблокирован -" : "" : "")+" ")]},proxy:true}:null,{key:"navbar-title",fn:function(){return [_vm._v(_vm._s(_vm.sourceObject.results ? _vm.sourceObject.results.title : ""))]},proxy:true},{key:"navbar-btn-wrap",fn:function(){return [(
        _vm.sourceObject.results &&
          _vm.sourceObject.results.is_active &&
          _vm.getCurrentRole !== 31
      )?_c('BlockPopup',{attrs:{"name":"Группу пользователей","title":_vm.sourceObject.results ? _vm.sourceObject.results.title : '',"block-function":_vm.banUserGroup}}):_vm._e(),(
        !(_vm.sourceObject.results && _vm.sourceObject.results.is_active) &&
          _vm.getCurrentRole !== 31
      )?_c('UnBlockPopup',{attrs:{"name":"Группу пользователей","title":_vm.sourceObject.results ? _vm.sourceObject.results.title : '',"un-block-function":_vm.unBlockUserGroup}}):_vm._e(),(_vm.getCurrentRole !== 31 && _vm.sourceObject.results.role !== 120)?_c('DeletePopup',{attrs:{"name":"Группу пользователей","title":_vm.sourceObject.results ? _vm.sourceObject.results.title : '',"delete-function":_vm.deleteUserGroup}}):_vm._e(),(_vm.urlQuery && _vm.urlQuery.id && _vm.getCurrentRole !== 31)?_c('router-link',{staticClass:"btn btn-edit btn_color_grey",attrs:{"to":`/userGroupsEdit/` + _vm.urlQuery.id}},[_vm._v("редактировать")]):_vm._e()]},proxy:true}],null,true)},[_c('SlideLayout',{attrs:{"tabs":_vm.slideTabs},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('div',{staticClass:"keep__title"},[_c('p',{staticClass:"keep__text"},[_vm._v("Группы пользователей")])]),(_vm.sourceObject.results)?_c('BasicTableWithoutHeader',{attrs:{"table-data":_vm.getDataRequisites}}):_vm._e()]},proxy:true},(!_vm.isObject)?{key:"users",fn:function(){return [_c('UsersGroupUsers')]},proxy:true}:{key:"users",fn:function(){return [_c('UsersGroupUsersObjects')]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }